import { motion } from 'framer-motion';
import React, { FC } from 'react';

import { InView } from 'react-intersection-observer';

interface Props {
  children: any;
  delay?: number;
  stagger?: number;
}

const Reveal: FC<Props> = ({ children, delay = 0, stagger = 0 }) => {
  return React.Children.map(children, (child, i) => (
    <InView threshold={0.25} triggerOnce>
      {({ ref, inView }) => (
        <motion.div
          ref={ref}
          key={`reveal-${i}`}
          transition={{
            type: 'spring',
            mass: 2,
            damping: 25,
            delay: delay + stagger * i,
          }}
          variants={{
            show: { x: 0, opacity: 1 },
            hide: { x: -50, opacity: 0 },
          }}
          initial="hide"
          animate={inView && 'show'}
          className="w-full"
        >
          {child}
        </motion.div>
      )}
    </InView>
  ));
};

export default Reveal;
